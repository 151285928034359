<script setup>
import {ref} from "vue";


/**
 * Data
 */
const blockOverlay = ref(false);


/**
 * Computed
 */


/**
 * Actions
 */
</script>

<template>
	<Dialog
		v-model:visible="blockOverlay"
		class="block-overlay"
		appendTo="self"
		:closeOnEscape="false"
		:showHeader="false"
		modal
	>
		<ProgressSpinner
			style="width:5vw;height:5vw"
			strokeWidth="8"
			fill="var(--surface-ground)"
			animationDuration=".5s"
		/>
	</Dialog>

	<div>
		<h3 class="text-center">คู่มือการใช้งานสำหรับผู้ใช้งาน</h3>

		<Card style="max-width: 1100px; margin: 0 auto;">
			<template #title>
				<h4 class="underline">การลงทะเบียน</h4>
			</template>
			<template #content>
				<ul class="list-decimal text-lg">
					<li>เข้าสู่ระบบการทดสอบโดยพิมพ์ Url: <a href="http://www.research-exam.com">http://www.research-exam.com</a></li>
					<li>
						กรอกเบอร์โทรศัพท์เคลื่อนที่ กดดำเนินการต่อเพื่อรับหัสเข้าสู่ระบบลงทะเบียน
						<img
							src="@/assets/manual/pic1.jpg"
							class="mt-3 mb-6 max-w-full"
						/>
					</li>
					<li>
						รับรหัส OTP จากข้อความในโทรศัพท์เคลื่อนที่ ซึ่งเป็นตัวเลข จำนวน 6 หลัก นำมากรอกในช่อง รหัส OTP แล้วกด ตรวจสอบ
						<img
							src="@/assets/manual/pic2.jpg"
							class="mt-3 mb-6 max-w-full"
						/>
					</li>
					<li>
						ไปที่ เมนู > โปรไฟล์ กรอกชื่อ นามสกุล อีเมล์ เบอร์โทรศัพท์ วิทยาเขต ชั้นปี แล้วกดบันทึก และแนบรูปถ่าย
						<img
							src="@/assets/manual/pic3.jpg"
							class="mt-3 mb-6 max-w-full"
						/>
					</li>
					<li>เมื่อผู้เข้ารับการทดสอบดำเนินการเข้าระบบเรียบร้อยแล้ว จะพบหน้าคู่มือการใช้งาน</li>
					<li>
						เมื่อคลิก เริ่มทำแบบทดสอบ จะเข้าสู่ห้องสอบ ซึ่งจะแสดงเวลาในการทำข้อสอบ 25 นาที และจำนวนข้อสอบทั้งหมด 24 ข้อ ให้ผู้เข้ารับการทดสอบทำแบบทดสอบโดยแต่ละข้อเลือกคำตอบที่ถูกต้อง เมื่อทำข้อสอบเสร็จเรียบร้อยแล้ว ให้ผู้เข้ารับการทดสอบกด ส่งคำตอบ
						<img
							src="@/assets/manual/pic4.jpg"
							class="mt-3 max-w-full"
						/>
						<img
							src="@/assets/manual/pic5.jpg"
							class="mt-1 max-w-full"
						/>
						<img
							src="@/assets/manual/pic6.jpg"
							class="mt-1 max-w-full"
						/>
						<img
							src="@/assets/manual/pic7.jpg"
							class="mt-1 mb-6 max-w-full"
						/>
					</li>
					<!-- <li>หลังจากนั้นผู้เข้ารับการทดสอบต้องทำการประเมินความพึงพอใจของผู้ใช้งานต่อระบบการทดสอบที่มีการให้ข้อมูลย้อนกลับโดยใช้คอมพิวเตอร์เพื่อการวัดสมรรถนะวิชาชีพครู: สาระความรู้การวิจัยเพื่อแก้ปัญหาและพัฒนาผู้เรียน เพื่อเข้าดูรายงานผลการทดสอบ</li> -->
				</ul>
				<div class="text-lg">
					หลังจากนั้นผู้เข้ารับการทดสอบต้องทำการประเมินความพึงพอใจของผู้ใช้งานต่อระบบการทดสอบที่มีการให้ข้อมูลย้อนกลับโดยใช้คอมพิวเตอร์เพื่อการวัดสมรรถนะวิชาชีพครู: สาระความรู้การวิจัยเพื่อแก้ปัญหาและพัฒนาผู้เรียน เพื่อเข้าดูรายงานผลการทดสอบ
				</div>
			</template>
		</Card>

		<Card style="max-width: 1100px; margin: 0 auto;" class="mt-6">
			<template #title>
				<h4 class="underline">การรายงานผลการทดสอบ</h4>
			</template>
			<template #content>
				<div class="text-lg">
					เมื่อผู้เข้ารับการทดสอบทำแบบทดสอบครบตามเงื่อนไขจะแสดงรายละเอียดผลการทดสอบดังนี้
				</div>
				<ul class="list-decimal text-lg">
					<li>
						ระบบจะรายงานผลการทดสอบในแต่ละครั้ง ให้ผู้เข้ารับการทดสอบ กดที่ รูป   เพื่อดูผลการทดสอบ โดยระบบจะรายงานผลการทดสอบเป็นกราฟและคะแนนในแต่ละขอบข่าย  และข้อเสนอแนะในแต่ละขอบข่าย มีรายละเอียด ดังนี้
						<ul>
							<li>ขอบข่าย A การวิเคราะห์ปัญหาการเรียนรู้</li>
							<li>ขอบข่าย B การออกแบบการวิจัยเพื่อพัฒนาการเรียนรู้</li>
							<li>ขอบข่าย C การนำผลการวิจัยไปใช้ในการพัฒนาผู้เรียน</li>
							<li>ขอบข่าย D การนำผลการวิจัยไปใช้ในการพัฒนาการเรียนการสอน</li>
							<li>ขอบข่าย E การแลกเปลี่ยนเรียนรู้ผลการวิจัย</li>
						</ul>
						<img
							src="@/assets/manual/pic8.jpg"
							class="mt-3 max-w-full"
						/>
						<img
							src="@/assets/manual/pic9.jpg"
							class="mt-1 max-w-full"
						/>
					</li>
				</ul>
			</template>
		</Card>
	</div>
</template>
